"use strict";
const defaultImage = require('@assets/icon/img-placeholder.svg');
(() => {
    const handleOnError = (image) => {
        if (image.parentElement instanceof HTMLPictureElement) {
            image.style.display = 'none';
            image.parentElement.classList.add('fallback');
        }
        else {
            image.removeAttribute('srcset');
            image.src = defaultImage;
            image.classList.add('fallback');
        }
    };
    const setupImageErrorHandler = (image) => {
        image.onerror = () => {
            handleOnError(image);
        };
    };
    const getCloudFlareImages = (element) => Array.from(element.querySelectorAll('img')).filter((img) => img.src.includes('cloudfront'));
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            mutation.addedNodes.forEach((node) => {
                if (node instanceof HTMLElement) {
                    getCloudFlareImages(node).forEach(setupImageErrorHandler);
                }
            });
        });
    });
    getCloudFlareImages(document.body).forEach(setupImageErrorHandler);
    observer.observe(document.body, { childList: true, subtree: true });
})();
